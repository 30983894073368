import "./src/styles/global.css"

import React from "react"
import { AnimatePresence } from "framer-motion"
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

/*
 * This helps enable page transitions when navigating to a new page.
 *
 * This is built based on this guide: https://janessagarrow.com/blog/gatsby-framer-motion-page-transitions/
 */
export const wrapPageElement = ({ element }) => (
  <AnimatePresence mode="wait">{element}</AnimatePresence>
)

/*
*  Microsoft Authentication Setup
*
*/
const msalInstance = new PublicClientApplication(msalConfig);
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

msalInstance.addEventCallback((event) => {
    console.log("MSAL EVENT" + JSON.stringify(event));
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export const wrapRootElement = ({ element }) => (
  <MsalProvider instance={msalInstance}>
    {element}
  </MsalProvider>
)
